<template>
    <div class="managsystem-task-tracking">
        <div class="block-box">
            <div class="head-box">
                <div class="title">任务跟踪</div>
                <div class="date-filter">
                    <DatePicker type="date" :options="datePickerOptions" v-model="date" placeholder="选择日期" style="width: 300px" />
                </div>
            </div>
            <div class="content-box">
                <div class="table-box">
                    <Table :tableColumn="tableColumn" :tableData="tableData"></Table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "./childrens/Table.vue"
import DateUtil from "./utils/date"
import Street from "./utils/street"

export default {
    components: { Table },

    data() {
        return {
            date: DateUtil.getDateByDay(-1),
            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() >= new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
            tableColumn: [
                {
                    title: "序号",
                    minWidth: 70,
                    align: "center",
                    type: "index",
                },
                {
                    title: "类型",
                    minWidth: 140,
                    align: "center",
                    render: (h, params) => {
                        if (params.row.onlineStatus === "1") {
                            return h("p", null, ({ 1: "市级", 2: "区级", 3: "街镇级" }[params.row.onlineType] || "") + "示范社区")
                        }
                        return h("p", null, { 2: "试用社区", 3: "非试用社区" }[params.row.onlineStatus] || "-")
                    },
                },
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "社区居民名册是否导入",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.residents ? "#57a3f3" : "",
                                },
                            },
                            params.row.residents ? "已导入" : "未导入"
                        )
                    },
                },
                {
                    title: "网格党支部是否导入",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.party ? "#57a3f3" : "",
                                },
                            },
                            params.row.party ? "已导入" : "未导入"
                        )
                    },
                },
                {
                    title: "网格党小组是否导入",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.group ? "#57a3f3" : "",
                                },
                            },
                            params.row.group ? "已导入" : "未导入"
                        )
                    },
                },
                {
                    title: "15分钟生活圈是否提交",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.fifteenMinutes ? "#57a3f3" : "",
                                },
                            },
                            params.row.fifteenMinutes ? "已提交" : "未提交"
                        )
                    },
                },
            ],
            tableData: [],
        }
    },

    watch: {
        date() {
            this.getCommunityData()
        },
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        this.getCommunityData()
    },

    methods: {
        getCommunityData() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listCommunityValue",
                {
                    dataScopeId: this.orgPath, // 范围编码
                    statDate: this.$core.formatDate(this.date, "yyyy-MM-dd"), // 统计时间
                    selectedField: ["orgCode", "orgName", "onlineType", "onlineStatus", "residents", "party", "group", "fifteenMinutes"], // 查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.tableData = res.dataList
                    }
                })
                .finally(this.$Message.destroy)
        },
    },
}
</script>

<style lang="less">
.managsystem-task-tracking {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .block-box {
        width: 100%;
        padding: 0 20px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: #333;
            }

            .date-filter {
                margin: 0 20px;
            }
        }

        .content-box {
            width: 100%;

            .table-box {
                margin: 10px 0;
                padding: 10px;
                height: 500px;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                overflow: hidden;
            }
        }
    }
}
</style>
