import { get } from "@/api/index"
import { Modal } from "view-design"

export default class Street {
    _info

    static async getInfo() {
        if (this._info) {
            return this._info
        }

        return new Promise(resolve => {
            get("/gx/pc/organizationScope/selectOrgManageScope", {
                orgCodeSelf: parent.vue?.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    let data = res.data
                    // 没有街道 orgCode 则提示
                    if (!data.orgCode) {
                        return Modal.error({
                            title: "提示",
                            content: "当前账号暂无街道权限！",
                        })
                    }
                    // 缓存
                    this._info = {
                        ...data,
                        orgPath: data.orgPath.replace(/,/g, "-"),
                    }
                    // 返回数据
                    resolve(this._info)
                }
            })
        })
    }

    /**
     * 获取社区类型
     */
    static getCommunityType(v) {
        if (v.onlineStatus === "1") {
            return ["市", "区", "街镇"][v.onlineType - 1] + "级示范社区"
        }

        return ["试用社区", "非使用社区"][v.onlineStatus - 2]
    }
}
