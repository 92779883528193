export default class DateUtil {
    static getDateByDay(day = 0, date) {
        date = date ? new Date(date) : new Date()
        // day 不为空的时候，改变 date 值
        if (day !== 0) {
            date.setDate(date.getDate() + day)
        }
        // 返回 Date 实体
        return date
    }
}
