<template>
  <div class="table" id="table">
      <Table :height="tableHeight" :border="tableData && tableData.length > 0" stripe class="table-div" :columns="tableColumn" :data="tableData"></Table>
  </div>
</template>

<script>
export default {
    props:['tableData','tableColumn','height'],
    data(){
        return{
            tableHeight:0
        }
    },
    created(){
        if(this.height && this.height != ''){
            this.tableHeight = this.height
        }else{
            this.$nextTick(()=>{
                let data = document.getElementsByClassName('table')[0].scrollHeight
                this.tableHeight = data
            })
            
        }
    }
}
</script>

<style lang="less" scoped>
.table{
    width: 100%;
    height: 100%;
    border: 1px solid #e8eaec;
    .table-div{
        width: 100%;
        height: 100%;
        
    }
}
</style>